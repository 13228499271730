$body-bg: #ffffff;
$body-color: #0e2b67;
$font-family-sans-serif: "Nunito", sans-serif;
//$border-radius: 10px;

$primary: #dc142d;
$secondary: #0e2b67;
$success: #0f996d;
$info: #a8c8f2;
$warning: yellow;
$danger: red;
$light: #fcfcfc;
$dark: black;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

$custom-colors: (
  "default": #969696,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800&display=swap");
@import "./misc.scss";
@import "./cardelement.scss";

.form-control {
  height: 48px;
}
.btn {
  min-height: 48px !important;
}
.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grecaptcha-badge {
  opacity: 0;
}

.axeptio_mount,
#crisp-chatbox {
  display: none !important;
}
