.cursor-pointer {
  cursor: pointer;
}
.Toastify__toast {
  min-height: auto !important;
}
.navigation-block {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 77%;
    background: #d6d6d6;
  }

  &:not(.first):not(.last) {
    &.active::after {
      background: linear-gradient(90deg, #030ca5 50%, #d6d6d6 50%);
    }

    &.complete::after {
      background: #030ca5;
    }
  }
  &.first::after {
    background: linear-gradient(90deg, transparent 50%, #d6d6d6 55%);
  }
  &.first.complete::after {
    background: linear-gradient(90deg, transparent 50%, #030ca5 50%);
  }
  &.last::after {
    background: linear-gradient(90deg, #d6d6d6 50%, transparent 50%);
  }
  &.last.active::after {
    background: linear-gradient(90deg, #030ca5 50%, transparent 50%);
  }
  &.last.complete::after {
    background: linear-gradient(90deg, #030ca5 50%, transparent 50%);
  }
}

.navigation-block-text {
  font-size: 16px;
}
.company-card {
  transition: 400ms;
  background: linear-gradient(45deg, white, white);
  background-size: 100% 135px;
  background-size: 100% 135px;

  //border: 1px solid #ced4da;

  &:hover {
    //background-color: #eef4ff;
  }

  &.clickable::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 6px;
    width: 100%;
    transform: scaleX(0);
    transform-origin: left;
    background: $success;
    transition: 500ms;
  }

  &.selected {
    &::after {
      transform: scaleX(1);
    }
  }
}

@media screen and (max-width: 486px) {
  .navigation-block-text {
    font-size: 12px;
  }
}

.opacity-on-hover:hover {
  opacity: 1 !important;
}
